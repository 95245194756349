import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Col, Row } from "react-bootstrap";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="section">
      <Row>
        <Col>
          <h1>Not Found</h1>
          <p>Darn! That page doesn't exist here.</p>
        </Col>
      </Row>
    </section>
  </Layout>
);

export default NotFoundPage;
